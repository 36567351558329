import React, { useState } from "react";
import useFetch, { useFetchWithoutAuth } from "../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { create } from "../services/upload-files.service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { auth, googleProvider, signInWithPopup } from "../firebase/Firebase";

export default function Register() {
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isOpen, setIsOpen] = useState({ userType: false, helpArea: false });
  const navigate = useNavigate();

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const handleDropdownClick = (dropdownName) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }));
  };

  const { data } = useFetchWithoutAuth('get-help-area');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        const res = await create(formData, "register");
        if (res.user && res.user.affectedRows > 0) {
          showMessage("success", "Successfully registered", 3000);
          setTimeout(() => {
            navigate("/", { state: { user: res.user } });
          }, 1000);
        } else {
          if (typeof res === "string" && res.includes("user already exists")) {
            showMessage("err", "This email is already registered. Please use a different email.", 3000);
          } else {
            showMessage("err", "Please enter valid information", 3000);
          }
        }
      } catch (error) {
        showMessage("err", "An error occurred. Please try again later.", 3000);
      }
      setFormData({});
    }
  };

  const handleSocialLogin = async (provider) => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userData = {
        name: user.displayName,
        email: user.email,
        user_type: "mentees",
        help_area: 1,
        password: user.displayName,
      };

      const res = await create(userData, "register");

      if (typeof res === "string" && res.includes("user already exists")) {
        showMessage("err", "User already exists. Please log in instead.", 4000);
        navigate("/", { state: { message: "User already exists. Please log in." } });
      } else if (res && res.affectedRows > 0) {
        showMessage("success", "Successfully registered via social login", 4000);
        navigate("/", { state: { user: res } });
      } else {
        showMessage("err", "Registration failed via social login", 4000);
      }
    } catch (error) {
      showMessage("err", "An error occurred during social login. Please try again.", 5000);
    } finally {
      setLoading(false);
    }
  };

  const showMessage = (type, message, duration) => {
    setValidationErrors({ [type]: message });
    setTimeout(() => setValidationErrors({}), duration);
  };

  const validateFormFields = (formData) => {
    const errors = {};
    if (!formData.name || formData.name.trim() === "") {
      errors.name = "Please enter name";
    }

    if (!formData.email || formData.email.trim() === "") {
      errors.email = "Please enter an email address";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
    } else if (!isAllowedDomain(formData.email)) {
      errors.email = "Email domain is not allowed";
    }

    if (!formData.password || formData.password.trim() === "") {
      errors.password = "Please enter a password";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long"
    } else if (!/[A-Z]/.test(formData.password)) {
      errors.password = 'Password must include at least one uppercase letter';
    } else if (!/[0-9]/.test(formData.password)) {
      errors.password = 'Password must include at least one number';
    } else if (!/[!@#$%^&*]/.test(formData.password)) {
      errors.password = 'Password must include at least one special character';
    }

    if (!formData.user_type || formData.user_type.trim() === "") {
      errors.user_type = "Please select user type";
    }

    if (!formData.help_area || formData.help_area.trim() === "") {
      errors.help_area = "Please select help area";
    }

    return errors;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isAllowedDomain = (email) => {
    const allowedDomains = ["mail.com", "gmail.com", "yahoo.com", "outlook.com"];
    const domain = email.split('@')[1];
    return allowedDomains.includes(domain);
  };


  return (
    <>
      <div className="form-container sign-up-container">
        <form onSubmit={handleSubmit}>
          {validationErrors.success && <div className="success-message">{validationErrors.success}</div>}
          {validationErrors.err && <div className="error-message">{validationErrors.err}</div>}
          <h1>Create Account</h1>
          <div className="social-container">
            {/* <a href="#" className="social" onClick={() => handleSocialLogin(facebookProvider)}>
              <i className="fab fa-facebook-f"></i>
            </a> */}
            <a href="#" className="social" onClick={() => handleSocialLogin(googleProvider)}>
              <i className="fab fa-google-plus-g"></i>
            </a>
          </div>
          <span>or use your email for registration</span>
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={formData.name || ''}
            onChange={handleInputChange}
          />
          {validationErrors.name && <div className="error login-error">{validationErrors.name}</div>}
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email || ''}
            onChange={handleInputChange}
          />
          {validationErrors.email && <div className="error login-error">{validationErrors.email}</div>}
          <div className="password-container">
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              name="password"
              value={formData.password || ''}
              onChange={handleInputChange}
            />
            <FontAwesomeIcon
              icon={passwordVisible ? faEye : faEyeSlash}
              onClick={togglePasswordVisibility}
              className="eye-icon"
            />
          </div>
          {validationErrors.password && <div className="error login-error">{validationErrors.password}</div>}
          <div className={`select-container ${isOpen.userType ? "open" : ""}`}>
            <select
              name="user_type"
              onChange={handleInputChange}
              value={formData.user_type || ''}
              onClick={() => handleDropdownClick("userType")}>
              <option value="" disabled hidden> Select User Type</option>
              <option key={1} value={"mentor"}>
                Mentor
              </option>
              <option key={2} value={'mentees'}>
                Mentees
              </option>
            </select>
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`down-arrow ${isOpen.userType ? "open" : ""}`}
            />
          </div>
          {validationErrors.user_type && <div className="error login-error">{validationErrors.user_type}</div>}
          <div className={`select-container ${isOpen.helpArea ? "open" : ""}`}>
            <select
              name="help_area"
              onChange={handleInputChange}
              value={formData.help_area || ''}
              onClick={() => handleDropdownClick("helpArea")}>
              <option value="" disabled hidden> Select Help Area</option>
              {data.data && data.data.length > 0 ? (data.data.map((area) => (
                <option key={area.id} value={area.id}>
                  {area.title}
                </option>
              ))) : (
                <span>No Help Area available.</span>
              )}
            </select>
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`down-arrow ${isOpen.helpArea ? "open" : ""}`}
            />
          </div>
          {validationErrors.help_area && <div className="error login-error">{validationErrors.help_area}</div>}
          <button type="submit" >Sign Up</button>
        </form>
      </div>
    </>
  );
}
